<template>
  <v-container grid-list-md px-0 mb-4 pb-4 pt-0 mt-0>
    <v-layout row mb-3 wrap fluid>
      <v-flex xs12 sm6 pb-4 v-for="card in cards" :key="card.title">
        <v-card
          height="100%"
          class="flexcard"
          min-width="250px"
          max-width="600px"
        >
          <div height="400">
            <v-img min-height="200" max-height="400" :src="card.image">
              <v-card-title class="headline event-title justify-center py-1">
                {{ card.title }}
              </v-card-title>
            </v-img>
          </div>
          <v-card-text class="text-xs-center text-sm-left grey--text">
            <div v-html="card.desc"></div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: "%s | Our events",
    link: [
      { rel: "canonical", href: "https://www.manchesterleathermen.com/events" },
    ],
    meta: [
      { itemprop: "name", content: "Our Events ← Manchester Leathermen " },
      {
        itemprop: "description",
        content:
          "We hold a monthly social on the first Saturday of each month.",
      },
    ],
  },
  data: () => ({
    cards: [
      {
        title: "The Leather Social",
        image: "/assets/social2024.jpg",
        desc: '<p>We hold a monthly social on the first Saturday of each month.</p><p>We recognise that not everyone who wants to come to our social has leather gear so we don’t have a dress code.</p><p>The Social is at the Lodge of the <a href="https://goo.gl/maps/m6paTmnfigBr1VnE9" style="color:#fff" rel="noreferrer" target="_blank">Eagle club, Bloom Street</a> on the <strong>first Saturday of each month from 7:30 p.m.</strong></p>',
      },
      /* {
        title: "The MLM Workshop",
        image: "/assets/leathermen_color2.jpg",
        desc:
          "<p>For those of you interested in all aspects of leather fetish and BDSM, we host a regular workshop in conjunction with the LGBT Foundation.</p><p>Focusing on education and the tradition of passing on knowledge, we’ve had topics as diverse as rope bondage for beginners to cigar smoking.</p><p>There is normally a workshop every month before the Social, but keep an eye on our Facebook page for the latest information.</p>",
      },
      {
        title: "Afternoon Tea with Leathermen",
        image: "/assets/2926.jpg",
        desc:
          "<p>One of our occasional events started by a member – we love it when members take the initiative for informal events, and we’ll always support you!</p><p>Join us on 24 June at the Richmond Tea Rooms for the only Afternoon Tea where the leather smells better than the cake.</p><p>Please note that a deposit may be required by the Tea Rooms – details will be on our Facebook page. </p>",
      },       */
      {
        title: "MLM at Manchester Pride",
        image: "/assets/man-pride.jpg",
        desc: "<h2>Summer 2024</h2><p>Manchester Leathermen participates at Manchester Pride. We'll publish details on how to join us there closer to the date.</p>",
      },
      {
        title: "MLM at Liverpool Pride",
        image: "/assets/lpl-pride.jpg",
        desc: "<h2>Summer 2024</h2><p>Manchester Leathermen participates at Liverpool Pride. We'll publish details on how to join us there closer to the date.</p>",
      },
    ],
  }),
};
</script>

<style>
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
